@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.intro {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  padding: 20px 30px;
  box-sizing: border-box;
  .lside {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .name {
    margin: 20px 20px 0 20px;
    width: 100%;

    .hi {
      font-family: "Rubik Mono One", sans-serif;
    }
    h1 {
      font-family: "Rubik Mono One", sans-serif;
      font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
      text-transform: uppercase;
      font-weight: 100;
      //   -webkit-text-stroke: 1px #000;
      //   color: transparent;
    }
  }
}
.intro-img:hover {
  .rotating {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }
}
.rotating {
  -webkit-animation: rotating 16s linear infinite;
  -moz-animation: rotating 16s linear infinite;
  -ms-animation: rotating 16s linear infinite;
  -o-animation: rotating 16s linear infinite;
  animation: rotating 16s linear infinite;
  
}
.imgcontainer {
  position: relative;
}
.lvls {
  position: relative;

  width: 300px;
  height: 300px;
 
}
.myimg {
  background: url(../../assets/me.jpg);
  background-size: 150px;
  border-radius: 50%;
  top: 75px;
  left: 75px;
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 4;
}
.level {
  border-radius: 50%;
  border: 1px solid #e7e7e73d;
  .skill {
    width: 30px;
    height: 30px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }
  .icon {
    width: 30px;
    height: 30px;
    background-size: 30px;
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: filter ease 0.4s;
  }
}
.lvl1 {
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  position: absolute;
  transform: rotate(-15deg);

  .circ {
    @include on-circle($item-count: 3, $circle-size: 300px, $item-size: 30px);
  }
}
.lvl2 {
  top: 25px;
  left: 25px;
  width: 250px;
  height: 250px;
  position: absolute;
  transform: rotate(245deg);
  .circ {
    @include on-circle($item-count: 3, $circle-size: 250px, $item-size: 30px);
  }
}
.lvl3 {
  top: 50px;
  left: 50px;
  width: 200px;
  height: 200px;
  position: absolute;
  transform: rotate(150deg);

  .circ {
    @include on-circle($item-count: 3, $circle-size: 200px, $item-size: 30px);
  }
}

.skill .html {
  background-image: url("../../assets/icons/html.svg");
  transform: rotate(17deg);
}
.skill .css {
  background-image: url("../../assets/icons/css.svg");
  transform: rotate(17deg);
}
.skill .js {
  background-image: url("../../assets/icons/js.svg");
  transform: rotate(17deg);
}
.skill .angular {
  background-image: url("../../assets/icons/angular.svg");
  transform: rotate(115deg);
}
.skill .react {
  background-image: url("../../assets/icons/react.svg");
  transform: rotate(26deg);
}
.skill .chrome {
  background-image: url("../../assets/icons/chrome.svg");
  transform: rotate(17deg);
}
.skill .nodejs {
  background-image: url("../../assets/icons/nodejs.svg");
  transform: rotate(210deg);
}
.skill .mongodb {
  background-image: url("../../assets/icons/mongodb.svg");
  transform: rotate(210deg);
}
.skill .linux {
  background-image: url("../../assets/icons/linux.svg");
  transform: rotate(213deg);
}
