@import url(https://fonts.googleapis.com/css?family=Rubik+Mono+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  25% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.intro {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  padding: 20px 30px;
  box-sizing: border-box; }
  .intro .lside {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .intro .name {
    margin: 20px 20px 0 20px;
    width: 100%; }
    .intro .name .hi {
      font-family: "Rubik Mono One", sans-serif; }
    .intro .name h1 {
      font-family: "Rubik Mono One", sans-serif;
      font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
      text-transform: uppercase;
      font-weight: 100; }

.intro-img:hover .rotating {
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.rotating {
  -webkit-animation: rotating 16s linear infinite;
  animation: rotating 16s linear infinite; }

.imgcontainer {
  position: relative; }

.lvls {
  position: relative;
  width: 300px;
  height: 300px; }

.myimg {
  background: url(/static/media/me.3098b68b.jpg);
  background-size: 150px;
  border-radius: 50%;
  top: 75px;
  left: 75px;
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 4; }

.level {
  border-radius: 50%;
  border: 1px solid #e7e7e73d; }
  .level .skill {
    width: 30px;
    height: 30px;
    box-shadow: 0 0 0 0 white; }
  .level .icon {
    width: 30px;
    height: 30px;
    background-size: 30px;
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    -webkit-transition: -webkit-filter ease 0.4s;
    transition: -webkit-filter ease 0.4s;
    transition: filter ease 0.4s;
    transition: filter ease 0.4s, -webkit-filter ease 0.4s; }

.lvl1 {
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  position: absolute;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg); }
  .lvl1 .circ {
    position: relative;
    width: 300px;
    height: 300px;
    padding: 0;
    border-radius: 50%;
    list-style: none; }
    .lvl1 .circ > * {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin: -15px; }
      .lvl1 .circ > *:nth-of-type(1) {
        -webkit-transform: rotate(0deg) translate(150px) rotate(0deg);
                transform: rotate(0deg) translate(150px) rotate(0deg); }
      .lvl1 .circ > *:nth-of-type(2) {
        -webkit-transform: rotate(120deg) translate(150px) rotate(-120deg);
                transform: rotate(120deg) translate(150px) rotate(-120deg); }
      .lvl1 .circ > *:nth-of-type(3) {
        -webkit-transform: rotate(240deg) translate(150px) rotate(-240deg);
                transform: rotate(240deg) translate(150px) rotate(-240deg); }

.lvl2 {
  top: 25px;
  left: 25px;
  width: 250px;
  height: 250px;
  position: absolute;
  -webkit-transform: rotate(245deg);
          transform: rotate(245deg); }
  .lvl2 .circ {
    position: relative;
    width: 250px;
    height: 250px;
    padding: 0;
    border-radius: 50%;
    list-style: none; }
    .lvl2 .circ > * {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin: -15px; }
      .lvl2 .circ > *:nth-of-type(1) {
        -webkit-transform: rotate(0deg) translate(125px) rotate(0deg);
                transform: rotate(0deg) translate(125px) rotate(0deg); }
      .lvl2 .circ > *:nth-of-type(2) {
        -webkit-transform: rotate(120deg) translate(125px) rotate(-120deg);
                transform: rotate(120deg) translate(125px) rotate(-120deg); }
      .lvl2 .circ > *:nth-of-type(3) {
        -webkit-transform: rotate(240deg) translate(125px) rotate(-240deg);
                transform: rotate(240deg) translate(125px) rotate(-240deg); }

.lvl3 {
  top: 50px;
  left: 50px;
  width: 200px;
  height: 200px;
  position: absolute;
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg); }
  .lvl3 .circ {
    position: relative;
    width: 200px;
    height: 200px;
    padding: 0;
    border-radius: 50%;
    list-style: none; }
    .lvl3 .circ > * {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin: -15px; }
      .lvl3 .circ > *:nth-of-type(1) {
        -webkit-transform: rotate(0deg) translate(100px) rotate(0deg);
                transform: rotate(0deg) translate(100px) rotate(0deg); }
      .lvl3 .circ > *:nth-of-type(2) {
        -webkit-transform: rotate(120deg) translate(100px) rotate(-120deg);
                transform: rotate(120deg) translate(100px) rotate(-120deg); }
      .lvl3 .circ > *:nth-of-type(3) {
        -webkit-transform: rotate(240deg) translate(100px) rotate(-240deg);
                transform: rotate(240deg) translate(100px) rotate(-240deg); }

.skill .html {
  background-image: url(/static/media/html.c83ea2f8.svg);
  -webkit-transform: rotate(17deg);
          transform: rotate(17deg); }

.skill .css {
  background-image: url(/static/media/css.23cb4196.svg);
  -webkit-transform: rotate(17deg);
          transform: rotate(17deg); }

.skill .js {
  background-image: url(/static/media/js.f4249c5d.svg);
  -webkit-transform: rotate(17deg);
          transform: rotate(17deg); }

.skill .angular {
  background-image: url(/static/media/angular.85f073da.svg);
  -webkit-transform: rotate(115deg);
          transform: rotate(115deg); }

.skill .react {
  background-image: url(/static/media/react.ca1bfcd2.svg);
  -webkit-transform: rotate(26deg);
          transform: rotate(26deg); }

.skill .chrome {
  background-image: url(/static/media/chrome.6d31b64f.svg);
  -webkit-transform: rotate(17deg);
          transform: rotate(17deg); }

.skill .nodejs {
  background-image: url(/static/media/nodejs.324728fa.svg);
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg); }

.skill .mongodb {
  background-image: url(/static/media/mongodb.4b9b2b94.svg);
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg); }

.skill .linux {
  background-image: url(/static/media/linux.7e06b003.svg);
  -webkit-transform: rotate(213deg);
          transform: rotate(213deg); }


html,
html[data-theme="default"] {
  --primaryColor: #009edd;
  --darkPrimaryColor: #004f6f;
  --secondaryBgColor: #12151d;
  --lightBgColor: #333742;
  --darkBgColor: #1b1f2a;
  --textColor: #ffffff; }

html[data-theme="other-theme"] {
  --primaryColor: #ffcb05;
  --secondaryBgColor: #12151d;
  --lightBgColor: #383b40;
  --homeTabs: #27313b;
  --darkBgColor: #1a1f2a; }

body {
  margin: 0;
  padding: 0; }

.black-bg {
  background: #000;
  color: #fff; }

.white-bg {
  background: #fff;
  color: #000; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

